import React from "react";
import { graphql } from "gatsby";
import Layout from "./../components/Layout";
import StandalonePageLayout, {
  WhiteBox,
} from "./../components/StandalonePageLayout";
/**
 * This is a generic page template. Figure out to do with background color! Right now it's a kludge.
 *
 */

const BasicPageTemplate = ({ data, pageContext }) => {
  const { title, richTextField } = data.craftEntryInterface;
  const myColor = [
    "var(--turquoise)",
    "var(--pink)",
    "var(--yellow)",
    "var(--green)",
    "var(--purple)",
    "var(--red)",
  ][Math.floor(Math.random() * 6)];
  // console.log(data, pageContext);
  return (
    <Layout backgroundColor={myColor} footerColor={myColor}>
      <StandalonePageLayout>
        <h1>{title}</h1>
        <WhiteBox>
          <div className="std-content" dangerouslySetInnerHTML={{ __html: richTextField }} />
         </WhiteBox>
      </StandalonePageLayout>{" "}
    </Layout>
  );
};

export default BasicPageTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    craftEntryInterface(id: { eq: $id }) {
      ... on Craft_standalonePage_standalonePage_Entry {
        id
        title
        richTextField
      }
    }
  }
`;
